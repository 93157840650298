import React, {useEffect, useState} from 'react';
import {Link, useParams} from 'react-router-dom';
import {AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer} from 'recharts';
import {lifeCareServices} from "../../utils";

const ServicesDetailsArea = () => {
    const defaultState = {id: undefined, title: undefined, description: undefined}
    const [service, setService] = useState(defaultState);
    const {catId} = useParams();

    useEffect(() => {
        const filterServices = lifeCareServices.filter(item => item.id.toString() === catId);
        if(filterServices.length > 0) {
            setService(filterServices[0]);
        }
        return () => {
            setService(defaultState)
        };
    }, [catId]);



    return (
        <>
            <div className="service-details-area pt-45">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-7 col-lg-8">
                            <article className="service-details-box">
                                {/*<div className="service-details-thumb mb-80">*/}
                                {/*   <img className="img" src="img/services/service-details-thumb1.jpg" alt="" />*/}
                                {/*</div>*/}
                                <div className="section-title pos-rel">
                                    {/*<div className="section-icon">*/}
                                    {/*   <img className="section-back-icon back-icon-left" src="img/section/section-back-icon-sky.png" alt="" />*/}
                                    {/*</div>*/}
                                    <div className="section-text pos-rel">
                                        <h1>{service.title}</h1>
                                    </div>
                                </div>
                                <div className="service-details-text mb-30"
                                     dangerouslySetInnerHTML={{__html: service.description}}/>
                            </article>
                        </div>
                        <div className="col-xl-5 col-lg-4">
                            <div className="service-widget mb-50">
                                <div className="widget-title-box mb-30">
                                    <h3 className="widget-title">More Information</h3>
                                </div>
                                <div className="more-service-list">
                                    <ul>
                                        {
                                            lifeCareServices.map((item, index) => {
                                                return (
                                                    <li key={index}>
                                                        {
                                                            item?.id ? <Link
                                                                to={`/details/${item.id}`}>{item.title}</Link> : <Link to={'/details'}>{item.title}</Link>
                                                        }
                                                    </li>
                                                )
                                            })
                                        }
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ServicesDetailsArea;