import React from 'react';
import useScript from "../../hooks/useScript";

const QurioLead = () => {
    useScript("https://theautoteam.leadshook.io/s/js_embed")

    return (
        <div
            style={{height: '100vh'}}
            className="quiz-container"
            data-quiz="0ebeN0drnCddbCfBo8BWPTu910Wb3CjzVjbCkfA6"
            data-offset="0"
            data-autoscroll="no"
        />
    );
};

export default QurioLead;
