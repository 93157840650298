import React from 'react';
import {Link} from 'react-router-dom';

const HomeFact = () => {
    return (
        <>
            <section className="fact-area fact-map primary-bg pos-rel pt-115 pb-60">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-6 col-lg-6 col-md-10">
                            <div className="section-title pos-rel mb-45">
                                <div className="section-text section-text-white pos-rel">
                                    <h1 className="white-color">We Are Always Ready To Help</h1>
                                </div>
                            </div>
                            <div className="section-button section-button-left mb-30">
                                <Link to="/contact" className="primary_btn btn-icon ml-0"><span>+</span>Make Appointment</Link>
                            </div>
                        </div>
                        <div className="col-lg-6 col-lg-6 col-md-8">
                            <div className="cta-satisfied">
                                <div className="single-satisfied mb-50">
                                    <h1>2K +</h1>
                                    <h5><i className="fas fa-user"/> Satisfied Patients</h5>
                                    <p>
                                        We have helped so many people get there diagnosis and get the help and support
                                        they needed
                                        to live a better life.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default HomeFact;