import {createContext, useEffect, useState} from "react";
import axios from "../api/axios";

const NewsContext = createContext({
    news: [],
    setNews: news => null
})

export const NewsProvider = ({children}) => {
    const [news, setNews] = useState([]);

    useEffect(() => {
        let isMounted = true;
        const controller = new AbortController();
        const getNews = async () => {
            try {
                const response = await axios.get('api/v1/messaging/news-set/?limit=1', {signal: controller.signal});
                isMounted && setNews(response.data);
            } catch (err) {
                console.log(err);
            }
        };
        getNews().then();
        setInterval(() => {
            getNews().then();
        }, 60000)
        return () => {
            isMounted = false;
            controller.abort();
        };
    }, []);

    const stateValues = [
        news
    ]

    return (
        <NewsContext.Provider value={stateValues}>{children}</NewsContext.Provider>
    )
}

export default NewsContext;