import moment from "moment";

export const howItWorks = [
    {title: 'Call Us', description: 'Call us today and talk to our team about any of our products or services fast.', moveIcon: "move-icon"},
    {title: 'Chat Online', description: 'Let us know how we can help you online today', moveIcon: "move-icon"},
    {title: 'Book Online', description: 'Book an appointment with us today directly from our site.'}
]

export const getDate = (epoch) => {
    const timestamp = typeof epoch === 'string' ? new Date(epoch).getTime() : epoch * 1000
    return moment(timestamp).format('MMMM D, YYYY h:mm A');
}