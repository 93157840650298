import React from 'react';
import { Link } from 'react-router-dom';

const HomeFourSingleService = ({title, description, id}) => {
   return (
      <>
         <div className="h4service-item">
            <div className="h4service-box mb-30">
               <div className="service-thumb pos-rel mb-0">
                  <img src={`img/services/${title}.png`} alt={title} width={"100%"}/>
                  {/*<Link className="h4services-tag green-bg white-color text-uppercase f-700" to="/servicesDetails">{name}</Link>*/}
               </div>
               <div className="service-content p-3 bg-white">
                  <h4>
                     {
                        id ? <Link to={`/details/${id}`}>{title}</Link>: title
                     }
                  </h4>
                  {/*<p className="mb-20">{description}</p>*/}
                  {/*<Link className="service-link" to={`/details/${id}`}>*/}
                  {/*   Read More <i className="fal fa-long-arrow-right"/>*/}
                  {/*</Link>*/}
               </div>
            </div>
         </div>
      </>
   );
};

export default HomeFourSingleService;