import React, {createContext, useState} from 'react';

const AuthContext = createContext({
    user: {},
    setUser: auth => null,
    setRefreshToken: token => null,
    getRefreshToken: () => null,
    logout: () => null
});

export const AuthProvider = ({children}) => {
    const [user, setUser] = useState({});

    const setRefreshToken = token => {
        localStorage.setItem('token', token)
    }

    const getRefreshToken = () => {
        return localStorage.getItem('token')
    }

    const logout = () => {
        setUser({});
        localStorage.clear();
    }


    const value = {
        user,
        setUser,
        getRefreshToken,
        setRefreshToken,
        logout
    }
    return (
        <AuthContext.Provider value={value}>
            {children}
        </AuthContext.Provider>
    );
};

export default AuthContext;