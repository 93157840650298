import React, {useState} from 'react';
import {Link} from 'react-router-dom';
import useGlobalContext from '../../../hooks/useGlobalContext';
import Sidebar from "../Sidebar/Sidebar";

const HomeFourFiveNavBar = () => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const {stickyMenu} = useGlobalContext();


    return (
        <>
            <header>
                <div className={stickyMenu ? "sticky_menu header-menu-area menu-area4 pl-55 pr-55" :
                    "header-menu-area menu-area4 pl-55 pr-55"}>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-xl-8 col-lg-12 col-md-12 d-flex align-items-center navbar__wrapper">
                                <div className="logo pos-rel">
                                    <Link to="/"><img src="/img/logo/logo-lifecare.png" alt="LifeCare"/></Link>
                                </div>
                                <div className="header__menu header__menu4 pl-60">
                                    <nav id="mobile-menu">
                                        <ul>
                                            <li><Link to="/">Home</Link></li>
                                            <li><Link to="/about">About</Link></li>
                                            <li><Link to="/contact">Contact</Link></li>
                                            <li><Link to="/sleep-apnea">What is OSA?</Link></li>
                                            {/*{!user.is_staff && <li><Link to="/chat/representatives">Representatives</Link></li>}*/}
                                            {/*<li><Link to="/chat">Chat</Link></li>*/}
                                        </ul>
                                    </nav>
                                </div>

                                <div onClick={handleShow} className="side-menu-icon d-lg-none text-end">
                                    <button className="side-toggle border-0 bg-transparent">
                                        <i className="fas fa-bars"/></button>
                                </div>

                            </div>
                            <div className="col-xl-4 col-lg-9 col-md-9 d-none d-xl-block">
                                <div className="header-right d-flex align-items-center justify-content-end"
                                     style={{lineHeight: "160px"}}>
                                    <div className="header-button pl-50">
                                        {
                                            // getRefreshToken() ?
                                            //     <span className={"btn-outline-success p-2 rounded-2 btn"} onClick={logout}>
                                            //         <i className={"fa fa-user"} style={{marginRight: "10px"}}/>{user?.first_name + ' ' + user.last_name}
                                            //     </span>
                                            //     :
                                            //     <Link className={"btn-outline-success p-2 rounded-2 btn"} to="/login">
                                            //         Sign In <i className="fa fa-arrow-right"/>
                                            //     </Link>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
            <Sidebar show={show} handleClose={handleClose} />
        </>
    );
};

export default HomeFourFiveNavBar;
