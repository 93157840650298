import React from 'react';
import ServicesDetailsArea from './ServicesDetailsArea/ServicesDetailsArea';
const ServicesDetails = () => {
    return (
        <>
            <ServicesDetailsArea/>
        </>
    );
};

export default ServicesDetails;