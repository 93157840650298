import {BrowserRouter, Route, Routes} from 'react-router-dom';
import ScrollTop from './components/ScrollTop';
import {AuthProvider} from './context/AllContext';
import AboutUs from './pages/AboutUs/AboutUs';
import Contact from './pages/Contact/Contact';
import HomeFour from './pages/HomeFour/HomeFour';
import Login from './pages/Login/Login';
import Register from './pages/Register/Register';
import './App.css';
import NotFound from './pages/NotFound/NotFound';
import {GlobalProvider} from "./context/global-context";
import RequireAuth from "./components/RequireAuth/RequireAuth";
import Chat from "./components/Chat/Chat";
import DoctorsOne from "./pages/Doctors/DoctorsOne/DoctorsOne";
import HomeFourFiveNavBar from "./components/Shared/HomeFourFiveNavBar/HomeFourFiveNavBar";
import React from "react";
import ClientOnly from "./components/ClientOnly/ClientOnly";
import {NewsProvider} from "./context/news-context";
import HomeFourFooter from "./pages/HomeFour/HomeFourFooter/HomeFourFooter";
import ServicesDetails from "./pages/Services/ServicesDetails/ServicesDetails";
import SleepApnea from "./pages/SleepApnea/SleepApnea";
import QurioLead from "./components/QurioLead/QurioLead";


function App() {
    return (
        <>
            <GlobalProvider>
                <AuthProvider>
                    <BrowserRouter>
                        <ScrollTop/>
                        <HomeFourFiveNavBar/>
                        <NewsProvider>
                            <Routes>
                                <Route path="/" element={<HomeFour/>}/>
                                <Route path={'/sleep-apnea'} element={<SleepApnea/>} />
                                <Route path={'/osa-test'} element={<QurioLead/>} />
                                <Route path="/details/:catId" element={<ServicesDetails/>}/>
                                <Route path="/about" element={<AboutUs/>}/>
                                <Route path="/contact" element={<Contact/>}/>
                                {/*<Route element={<RequireAuth/>}>*/}
                                {/*    <Route path="/chat" element={<Chat/>}/>*/}
                                {/*    <Route element={<ClientOnly/>}>*/}
                                {/*        <Route path="/chat/representatives" element={<DoctorsOne/>}/>*/}
                                {/*    </Route>*/}
                                {/*</Route>*/}
                                <Route path="/notMatch" element={<NotFound/>}/>
                                <Route path="*" element={<NotFound/>}/>
                            </Routes>
                            <HomeFourFooter/>
                        </NewsProvider>
                    </BrowserRouter>
                </AuthProvider>
            </GlobalProvider>
        </>
    );
}

export default App;
