import React from 'react';
import {Link} from 'react-router-dom';

const HomeFourSingleHeroSlide = () => {
    return (
        <>
            <div className="col-xl-8 col-lg-12 col-md-10">
                <div className="hero-text">
                    <div className="hero-slider-caption h4hero-content mb-35">
                        <h1 className="white-color">Wake Up To A New You</h1>
                        <p className="white-color">
                            Are you often tired during the day after a full night sleep? Have you been told that you
                            snore a lot during sleep? We may have the answers you are looking for. Chat with us today
                            online, over the phone or in person.
                        </p>

                        {/*<div className="h4-span">*/}
                        {/*    <span className="white-color"><i className="fal fa-arrow-circle-right"></i><span>Digital Laboratory & Computerized System</span></span>*/}
                        {/*    <span className="white-color"><i className="fal fa-arrow-circle-right"></i><span>Digital Laboratory & Computerized System</span></span>*/}
                        {/*</div>*/}

                    </div>
                    <div className="hero-slider-btn h4hero-btn">
                        <a href="tel:+18005655664" className="primary_btn btn-icon btn-icon-green ms-0"><span>+</span>Call Now</a>
                        <Link to="/osa-test" className="primary_btn btn-icon btn-icon-green ms-0"><span><i className="fal fa-arrow-circle-right"/></span>Take OSA Test Online</Link>
                    </div>
                </div>
            </div>
        </>
    );
};

export default HomeFourSingleHeroSlide;
