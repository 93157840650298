import React from 'react';
import {Link} from 'react-router-dom';
import {getDate} from "../../utils/config";

const HomeFourSingleBlog = ({title, description, modified_at, user}) => {
    return (
        <>
            <div className="col-md-12">
                <div className="card box-shadow mb-3 border-0">
                    <h2 className="card-header text-center bg-light py-3">
                        <Link to="/blogDetails">{title}</Link>
                    </h2>
                    <div className="card-body p-0 py-3">
                        <p dangerouslySetInnerHTML={{__html: description}}/>
                        <hr style={{border: "1px solid black"}} />
                        <span><i className="far fa-calendar-alt"/> {`${getDate(modified_at)}`}</span>
                    </div>
                </div>
            </div>
        </>
    );
};

export default HomeFourSingleBlog;
