import React from 'react';

const ContactMap = () => {
    return (
        <>
            <section className="map-area">

                    <div style={{ width: '100%' }}>
                        <iframe title="Contact" src="https://maps.google.com/maps?width=100%25&amp;&amp;hl=en&amp;q=36%20Oland%20Crescent%20Halifax,%20NS,%20B3S%201C6+(My%20Business%20Name)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed" style={{ width: '100%' }} height={600} allowFullScreen="" loading="lazy"/>
                    </div>

            </section>
        </>
    );
};

export default ContactMap;