import React from 'react';
import {Link} from "react-router-dom";

const HomeFourAbout = () => {
    return (
        <>
            <section className="about-area pt-40 pb-100">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-6 col-lg-5">
                            <div className="h4about-thumb pos-rel">
                                <img src="img/home4/about/about__thumb.png" alt=""/>
                                <a href="tel:+18005655664" className="call-btn f-700 white-color green-bg">
                                    <i className="call-icon"><img src="img/home4/icon/about__phone__icon.png" alt=""/>
                                    </i><span>Call : 1-800-565-5664</span></a>
                                <img src="img/home4/about/overlap.png" alt="" className="about-overlap__thumb"/></div>
                        </div>
                        <div className="col-xl-6 col-lg-7">
                            <div className="about-right-side h4about-right mb-30">
                                <div className="about-title mb-20">
                                    <h5 className="pink-color">LifeCare Respiratory Services</h5>
                                    <h1>With 33+ Years Experience You Are In Good Hands</h1>
                                </div>
                                <div className="about-text">
                                    <p className="theme-color">
                                        Family Owned and operated since 1989, LifeCare Respiratory Services LTD makes
                                        sure our clients
                                        receive the best products and services in the industry. We support the latest
                                        technology and equipment
                                        the industry offers for sleep apnea. With a strong product selection to meet the
                                        needs of every patient
                                        you can be assured that we can provide the care you deserve. Our mission will
                                        always be to improve the
                                        life of our clients and clinical outcomes of our clients with Sleep Apnea. <Link className={'primary-color'} to={'/chat'}>Chat
                                        Online Today</Link>
                                    </p>
                                </div>
                                <div className="about-author d-flex align-items-center">
                                    <div className="author-ava h4author-ava">
                                        <img src="img/logo/logo-lifecare.png" width={60} height={60} alt=""/>
                                    </div>
                                    <div className="author-desination h4author-destination">
                                        <h4 className="mb-0">Sandra</h4>
                                        <p>Owner of <Link to={'/about'} className={'primary-color'}>LifeCare Respiratory Services LTD</Link></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default HomeFourAbout;
