import React from 'react';

const SingleAppointmentInputField = ({placeholderText,icon, register, type, maxLength}) => {
    return (
        <>
            <div className="col-lg-6">
                <div className="h4appoinment-input pos-rel mb-45">
                    <input {...register} type={type} maxLength={maxLength} placeholder={placeholderText} required />
                    <i className="h4input-icon"><i className={icon}/></i>
                </div>
            </div>
        </>
    );
};

export default SingleAppointmentInputField;