import React from 'react';

const CommonSectionArea = ({area_header}) => {
    return (
        <>
            <section className="about-area pt-120 pb-90">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-6 col-lg-5">
                            {area_header}
                        </div>
                        <div className="col-xl-6 col-lg-7">
                            <div className="about-right-side pt-55 mb-30">
                                <div className="about-title mb-20">
                                    <h5>About Us</h5>
                                    <h2>Our dedication to helping our community has spanned over 33 years</h2>
                                </div>
                                <div className="about-text mb-50">
                                    <p>
                                        LifeCare came to be over 33 years and has provided the best products and service
                                        in our industry during
                                        that time. We keep our clients up to date with the latest products even after
                                        treatment.
                                    </p>
                                </div>
                                <div className="our-destination">
                                    <div className="single-item mb-30">
                                        <div className="mv-icon f-left">
                                            <img src="img/about/destination-icon-1.png" alt=""/>
                                        </div>
                                        <div className="mv-title fix">
                                            <h3>Our Mission</h3>
                                            <p>
                                                LifeCare provides sleep and respiratory care within the communities we serve and will strive to improve/enhance the quality of life to all those who need these services.
                                            </p>
                                        </div>
                                    </div>
                                    <div className="single-item">
                                        <div className="mv-icon f-left">
                                            <img src="img/about/destination-icon-2.png" alt=""/>
                                        </div>
                                        <div className="mv-title fix">
                                            <h3>Our Vision</h3>
                                            <p>
                                                Our vision is to keep making it easier for our clients to get the
                                                products and services they need. We
                                                now offer even more ways to communicate online and we will continue to
                                                develop new ways to support
                                                our new and existing clients.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default CommonSectionArea;
