export const lifeCareServices = [
    {
        id: 1,
        title: 'Ambulatory Sleep Studies',
        slug: 'ambulatory-sleep-studies',
        description: '<p>Sleep study is a test to diagnose sleep related breathing disorders, fits and other problems like somnambulism, commonly known as sleep walking.\n' +
            'The best to diagnose sleep related breathing disorders is polysomnography (PSG)</p>' +
            '<h5 class="green-color text-up-case mb-3">Benefits:</h5>' +
            '<p>For patients who have chronic breathing problem like sleep apnea, seizures(fits) and narcolepsy ' +
            '(excessive sleepiness) this test is ideal. Polysomnography helps in identifying the cause and ' +
            'disturbance in the sleep cycle.</p>' +
            '<h6>List of diseases that can be diagnosed by polysomnography:</h6>' +
            '<ul class="list-group list-group-flush mb-3">' +
            '<li class="list-group-item">Narcolepsy</li>' +
            '<li class="list-group-item">Periodic limb movement disorder</li>' +
            '<li class="list-group-item">REM sleep behaviour disorder</li>' +
            '<li class="list-group-item">Sleep apnea</li>' +
            '<li class="list-group-item">Chronic insomnia</li>' +
            '<li class="list-group-item">Neuromuscular problems</li>' +
            '<li class="list-group-item">Parasomnias</li>' +
            '</ul>' +
            '<h5 class="green-color text-up-case mb-3">Preparation for sleep study:</h5>' +
            '<p>The patient is advised to avoid caffeine, alcohol or anything which can cause changes in the patient’s sleep cycle. Sleeping during the day is usually discouraged.</p>' +
            '<h6>Procedure:</h6>' +
            '<p>While doing Polysomnography, electrodes are placed on the patient’s scalp, temple, chest and limbs with some adhesive. The wires of the electrodes are connected to a monitor from where the doctor can observe the waves of sleep cycle.\n' +
            'other than sleep cycle waves, many other physiological processes are continuously monitored.</p>' +
            '<h6>For example:</h6>' +
            '<ul class="list-group list-group-flush mb-3">' +
            '<li class="list-group-item">Blood pressure</li>' +
            '<li class="list-group-item">Oxygen saturation</li>' +
            '<li class="list-group-item">Eye movements</li>' +
            '<li class="list-group-item">Heart rate</li>' +
            '<li class="list-group-item">Breathing patterns</li>' +
            '<li class="list-group-item">Limb Movements</li>' +
            '<li class="list-group-item">Chest Movements</li>' +
            '</ul>' +
            '<h5 class="green-color text-up-case mb-3">Results:</h5>' +
            '<p>Any disruption in sleep waves or eye movements are a sign of sleep apnea or any other sleep disorder.\n' +
            'Changes in respiration or chest/abdominal movements and snoring indicate some respiratory pathology.\n' +
            'Limb movement show periodic limb movement disorder.\n' +
            'After results, the patient is advised for follow up for the proper treatment.\n' +
            'The polysomnography test results in 4 quantified classifications</p>' +
            '<ul class="list-group list-group-flush mb-3">' +
            '<li class="list-group-item">Normal</li>' +
            '<li class="list-group-item">Mild</li>' +
            '<li class="list-group-item">Moderate</li>' +
            '<li class="list-group-item">Severe</li>' +
            '</ul>' +
            '<p>And the treatment is different for each class.</p>',

    },
    {
        id: 2,
        title: 'Home Sleep Studies',
        slug: 'home-sleep-studies',
        description: '<p>Excessive daytime sleepiness is quite a common complaint in general population and it has associations with a number of sleep disorders and other health problems.</p>' +
            '<p>According to an estimate, almost 19% of people suffer from excessive day time sleepiness.</p>' +
            '<p>Excessive day time sleepiness (EDS) or hypersomnia is characterized as the urge and propensity to fall asleep during working hours and is more common in people having sedentary and passive situations.</p>' +
            '<p>They are unable to stay awake when alertness is required and suffer sleep attacks resulting in involuntary naps.</p>' +
            '<p>There are many causes of excessive day time sleepiness i.e </p>' +
            '<p><b>breathing disorders : </b>obstructive sleep apnea,snoring, large tonsils, large tongue,abnormal size of lower jaw bone</p>' +
            '<p><b>Metabolic causes : </b>narcolepsy,other hypersomnias </p>' +
            '<p><b>Diseases : </b>cancer, heart failure, iron deficiency anemia,diabetes</p>' +
            '<p><b>Medicines : </b>antipsychotic drugs, recreational drugs , benzodiazapines</p>' +
            '<p><b>Other cause : </b>poor sleep, insomnia,shift work sleep disorder</p>' +
            '<p>Given the broad range of possibilities that hypersomnias can present with, detailed history by the health professional and some tests must be done to diagnose it</p>' +
            '<p><b class="text-danger">Laboratory polysomnography</b> has been used traditionally by health professionals in sleep medicine.</p>' +
            '<p>However,due to increasing prevalence of EDS, very high technical requirements and economic burden <br> <b class="text-danger">HOME SLEEP APNEA TESTING</b>  evolved</p>' +
            '<p>Its use now has been wide spread in the whole world as it is accessible,convenient, has fewer technical requirements, is portable and comfortable for the patient.</p>' +
            '<h6>Diagnosis</h6>' +
            '<p>It is a simplified device that monitors oxygen levels,breathing patterns and effort done for breathing</p>' +
            '<h6>Complications</h6>' +
            '<p>Impotence, memory loss, stroke, heart attack. Headache, diabetes, arrhythmias, obesity are the conditions one can suffer from who is having sleep patterns disorders.</p>'
    },
    {
        id: 3,
        title: 'Sleep Apnea Diagnosis & Treatment',
        slug: 'sleep-apnea-diagnosis-treatment',
        description: '<p>Sleep apnea is characterized by cessation of airflow for at least 10 seconds.\n' +
            'It is quantified by sleep studies, polysomnography and other diagnostic procedures.</p>' +
            '<h6 class="text-danger">Sleep apnea is divided into two categories</h6>' +
            '<ul class="list-group list-group-flush mb-3">' +
            '<li class="list-group-item">Central sleep apnea</li>' +
            '<li class="list-group-item">Obstructive sleep apnea</li>' +
            '</ul>' +
            '<p>In patients with central sleep apnea, the cause most likely is related to heart or lungs</p>' +
            '<p>Whereas, in obstructive sleep apnea, the cause is obstruction to air flow from the nose or mouth.</p>' +
            '<p>As per an estimate, 9% of females and 24% of males have sleep apnea.</p>' +
            '<p>It is more common in middle aged males and those who snore.</p>' +
            '<p>25% of the heavy snorers have obstructive sleep apnea and consequently, increased death ratio and  increased functional disability.</p>' +
            '<h5 class="green-color">Obstructive sleep apnea</h5>' +
            '<p>It is a potentially life threatening condition characterized by heavy snoring, the spectrum includes the individuals who snore intermittently with little sleep disturbances, and those individuals who snore heavily and have severe gas exchange problems\n' +
            'The episodes may be even 600 times per night</p>' +
            '<h6>Obstructive Sleep Apnea can be due to large tonsils, nasal polyps, adenoids, large tongue, small sized jaw, acromegaly, amyloidosis (disease due to deposition of certain proteins in organs), hypothyroidism (disease of thyroid gland) and neurological problems</h6>' +
            '<p>Medical conditions that may result are arrythmias (diseases of heart rate and rhythm), high blood pressure, pulmonary hypertension (high blood pressure in arteries supplying blood to lungs) and sudden death.</p>' +
            '<h5 class="green-color">Treatment</h5>' +
            '<p>Patients with >20 episodes of apnea per hour with day time somnolence (increased sleep) and Patient with 5-20 episodes of apnea per hour with somnolence and other day time symptoms May show improvement and decreased mortality (death ratio in that specified group) if treated. And they can be benefitted from the non-pharmacological treatment </p>' +
            '<h6 class="text-danger">Treatment:</h6>' +
            '<ul class="list-group list-group-flush mb-3">' +
            '<li class="list-group-item">Treatment of the cause:</li>' +
            '<li class="list-group-item">Decrease weight</li>' +
            '<li class="list-group-item">Tonsillectomy (removal of the large tonsils)</li>' +
            '<li class="list-group-item">Nasal septum repair</li>' +
            '<li class="list-group-item">Polypectomy (removal of nasal polyps)</li>' +
            '<li class="list-group-item">Decrease work of breathing by the patient.</li>' +
            '<li class="list-group-item">CPAP (standard treatment for most OSAs)</li>' +
            '</ul>' +
            '<h6 class="text-danger">Pharmacological (DRUGS)</h6>' +
            '<ul class="list-group list-group-flush mb-3">' +
            '<li class="list-group-item">Selective serotonin reuptake inhibitors</li>' +
            '<li class="list-group-item">Tricyclic anti-depressants </li>' +
            '<li class="list-group-item">Antihypertensives</li>' +
            '<li class="list-group-item">Theophylline </li>' +
            '<li class="list-group-item">Medroprogesterone</li>' +
            '</ul>' +
            '<h6 class="text-danger">Central sleep apnea</h6>' +
            '<p>In this type of apnea, breathing effort is not affected in contrast to Obstructive Sleep Apnea.</p>' +
            '<p>Patients have gas exchange problem secondary to heart or lung issue and they have increased carbon dioxide levels in their blood resulting in headache and drowsiness. </p>' +
            '<h6 class="text-danger">Treatment</h6>' +
            '<p>Primary treatment for Central Sleep Apnea is CPAP with supplemental oxygen</p>' +
            '<h6 class="text-danger">Other drugs that can help are</h6>' +
            '<ul class="list-group list-group-flush mb-3">' +
            '<li class="list-group-item">Azetazolamide</li>' +
            '<li class="list-group-item">Theophylline</li>' +
            '<li class="list-group-item">Medroprogesterone</li>'+
            '</ul>'
    },
    {
        id: 4,
        title: 'CPAP Titration',
        slug: 'cpap-titration',
        description: '<p>It is a non-invasive, gentler mode of respiratory support for the patients needing positive pressure to keep the alveoli open for the gas exchange.</p>' +
            '<h5 class="green-color text-up-case mb-3">The CPAP helps in </h5>' +
            '<h6>List of diseases that can be diagnosed by polysomnography:</h6>' +
            '<ul class="list-group list-group-flush mb-3">' +
            '<li class="list-group-item">improved oxygenation of the blood </li>' +
            '<li class="list-group-item">proper wash out of the carbon dioxide</li>' +
            '<li class="list-group-item">Normalizes pH.</li>' +
            '<li class="list-group-item">Stimulates stretch(the system that controls respiration in lungs) receptors in the lungs and thus facilitates the normal functioning of the respiratory system.</li>' +
            '<li class="list-group-item">Provides better ventilation of lungs to match the oxygen demands of the body.</li>' +
            '<li class="list-group-item">Decrease work of breathing by the patient.</li>' +
            '<li class="list-group-item">Splints and supports the upper airway</li>' +
            '</ul>' +
            '<h5 class="green-color text-up-case mb-3">INDICATIONS AND USES</h5>' +
            '<h6>CPAP FINDS ITS USE IN PEDIATRIC AS WELL AS ADULT MEDICINE</h6>' +
            '<p>Diseases with airway instability (NEONATES-new born children less than 28 days)</p>' +
            '<ul class="list-group list-group-flush mb-3">' +
            '<li class="list-group-item">Breathing problems due to immature respiratory system</li>' +
            '<li class="list-group-item">Breathing problems due to abnormal development during pregnancy</li>' +
            '<li class="list-group-item">Those who have been on ventilator and now being removed from ventilator</li>' +
            '</ul>' +
            '<h5 class="green-color text-up-case mb-3">RESPIRATORY DISORDERS</h5>' +
            '<ul class="list-group list-group-flush mb-3">' +
            '<li class="list-group-item">Patchy atelectasis (collapse of some part of the lung)</li>' +
            '<li class="list-group-item">Aspiration(infection due to foreign substance mostly food or drinks spilled into lungs while eating)</li>' +
            '<li class="list-group-item">Pneumonia(infection of lung tissue)</li>' +
            '<li class="list-group-item">Acute exacerbation of chronic obstructive pulmonary disease (most common lung disease due to smoking)</li>' +
            '<li class="list-group-item">Bronchial asthma </li>' +
            '<li class="list-group-item">Trauma </li>' +
            '<li class="list-group-item">Cancer</li>' +
            '<li class="list-group-item">Respiratory insufficiency due to any cause</li>' +
            '</ul>' +
            '<h5 class="green-color text-up-case mb-3">Diseases related to cardio-respiratory system</h5>' +
            '<ol>Acute pulmonary edema (failure of heart to pump appropriately leading to fluid overload into lungs)</ol>' +
            '<ol>Pressure overload in the body </ol>'+
            '<ol>loss of myocardium (heart tissue)-mostly due to heart attack</ol>' +
            '<p>these are the indications in which CPAP can be used for better outcome and decreased death ratio and decreased functional disability of the patients.</p>' +
            '<h5 class="green-color text-up-case mb-3">HOW CPAP WORKS</h5>' +
            '<li class="list-group-item">maintains constant level of airway pressure</li>' +
            '<li class="list-group-item">keeps airways open </li>' +
            '<li class="list-group-item">moves fluid out of lungs</li>' +
            '<li class="list-group-item">improves gas exchange</li>' +
            '<li class="list-group-item">buys time for medicines to work</li>'
    },
    {
        id: 5,
        title: 'Nasal CPAPs',
        slug: 'nasal-cpaps',
        description: '<p>Almost one billion people around the world suffer from the sleep apnea. It causes periodic stopping of the breathing and that is repeated over night, it results into sleep disruptions and serious health problems.</p>' +
            '<p>he standard treatment of the obstructive sleep apnea is CPAP therapy that works by giving high pressured air to the airways to keep them open via nose and mouth or only nose.</p>' +
            '<p>Some people find it troublesome and uncomfortable to use heavy CPAP machine with tight mask over night and may feel claustrophobic.(irrational fear of being confined)</p>' +
            '<p>There are certain other accessories that can be used along with CPAP machine, among them are nasal mask and nasal pillow.</p>' +
            '<h6 class="text-danger">NASAL CPAP MASK</h6>' +
            '<p>The nasal CPAP mask covers the patient’s bridge of nose to the upper lip. This would deliver indirect pressured airflow via the nose or nasal cavity to airways and it is more suitable for the patients who need higher pressure settings.\n' +
            'The nasal CPAP treatment gives a number of options to the patient from a bulky face mask to light weight nasal pillow</p>' +
            '<p class="text-danger">The health care team recommend nasal CPAP mask to patients who</p>' +
            '<ol class="text-danger">Prefer more natural flow</ol>'+
            '<ol class="text-danger">Move a lot during sleep</ol>'+
            '<ol class="text-danger">Need higher pressure settings</ol>' +
            '<ol class="text-danger">Want to have personal selection of mask</ol>' +
            '<p>But there are some downsides of this device, patients who are:</p>' +
            '<ol>habitual mouth breathers</ol>' +
            '<ol>have deviated nasal septum</ol>' +
            '<ol>history of allergies and rhinitis</ol>' +
            '<ol>nasal polyps</ol>' +
            '<ol>nasal polyps</ol>' +
            '<p>can not use it.</p>' +
            '<h6 class="text-danger">Nasal Pillow</h6>' +
            '<p>Nasal pillows are small, light weight and compact devices used for the treatment of sleep apnea, the design allows the patient minimal face contact, but they work only with low to moderate pressure settings.</p>' +
            '<p>Heath care team offers nasal pillow CPAP treatment to the patients who</p>' +
            '<ol>Tilt and toss around during sleep</ol>' +
            '<ol>Have a lot facial hair</ol>' +
            '<ol>Claustrophobic of large face masks</ol>' +
            '<ol>Need low to moderate pressure settings for CPAP</ol>' +
            '<p>This also provide advantage for patients who would watch television or study before going to sleep,</p>' +
            '<p>Have beard and mustache and can wear glasses. </p>' +
            '<p>The biggest disadvantage is that high pressure settings cannot be applied.</p>',
        benefits: {description:'', diseasesHandles: []},
    },
    {
        id: 6,
        title: 'Bi-Level Therapy',
        slug: 'bi-level-therapy',
        description: '<p>Positive airway pressure treatment is the most commonly used treatment for obstructive sleep apnea.</p>' +
            '<p>It has different forms like CPAP, BIPAP.</p>' +
            '<h6>Bilevel therapy or BIPAP is indicated in obstructive sleep apnea not responding to CPAP treatment, central sleep apnea(sleep apnea due to lungs or heart issue not due to obstruction) and congestive heart failure </h6>' +
            '<p>Both the CPAP and BIPAP operate on the same principle of positive airway pressure that help open the alveoli of the lungs for better gas exchange but both the treatments have their upsides and downsides.</p>' +
            '<p>The BIPAP machine uses pressurized air that is directed into user’s airways via a hose and mask along with some other accessories.</p>' +
            '<p>The best CPAP machine have pressure setting 4-20mmH2O adjustable regardless the patient is taking air in or taking air out, it can cause some people  feel choked while exhaling the air.</p>' +
            '<p class="text-danger">Some of the patients of obstructive sleep apnea adjust to CPAP relatively quickly, while those who cannot tolerate are switched to BIPAP therapy.</p>' +
            '<p>The BIPAP machines are designed in a way that it increases the pressure when the patient is inhaling and decreases the pressure of air when he is exhaling the air. This helps in easy exhaling as the there is no pressure during that phase of respiration. </p>' +
            '<p>The CPAP machines have only one setting and the pressure during exhalation cannot be adjusted while the BIPAP machines are more diverse as compared to CPAP, it has multiple sensors and accessories that work in accordance with the pressure settings and patient needs.</p>' +
            '<p>The CPAP machine is portable and can be carried as per patients needs while BIPAP machine can only be used in hospital setting or at home.</p>' +
            '<p>The patients on CPAP have adjustment problems with the pressure while being treated, the adjustment period is managed by changing the setting, changing the accessories or climate change, the patient who still cannot tolerate CPAP are shifted to BIPAP therapy, the BILEVEL/BIPAP therapy is free of such problems as it have multiple settings and can be adjusted as per user’s needs.</p>' +
            '<p>But the choice of the treatment should strictly be under the supervision of health care team.</p>' +
            '<h6 class="text-danger">The BIPAP machine has two settings</h6>' +
            '<p>One for inhalation that uses high pressure and one for exhalation that is lighter so as to make people feel they are not fighting against the device and respire more naturally.</p>' +
            '<p>The advanced BIPAP machines use special sensors that are calibrated to timed switching between inhalation and exhalation or spontaneous switching.</p>',
    }
]