import React from 'react';
import {Link} from 'react-router-dom';

const HomeFourFooter = () => {
    return (
        <>
            <footer>
                <div className="footer-top h4_footer primary-bg footer-map pos-rel pt-120 pb-80">
                    <div className="container">
                        <div className="row justify-content-between">
                            <div className="col-xl-3 col-lg-6 col-md-6">
                                <div className="footer-contact-info footer-contact-info-3 mb-40">
                                    <div className="footer-logo">
                                        <Link to="/"><img src="/img/logo/logo-lifecare.png" height={98} alt=""/></Link>
                                    </div>
                                    <div className="footer-contact-content mb-25">
                                        <p className="p-0">
                                            LifeCare Respiratory Services LTD Is dedicated to providing the highest
                                            quality service and care in our
                                            field.
                                        </p>
                                    </div>
                                    <div className="footer-emailing">
                                        <ul>
                                            <li><i className="far fa-envelope"/>lifecare@bellaliant.com</li>
                                            <li><i className="far fa-clone"/>mylifecare.ca</li>
                                            <li><i className="far fa-flag"/>36 Oland Crescent Halifax, NS, B3S 1C6</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            {/*<div className="col-xl-4 col-lg-6 col-md-6">*/}
                            {/*    <div className="footer-widget h4footer-widget mb-40">*/}
                            {/*        <div className="footer-title">*/}
                            {/*            <h3>Recent News</h3>*/}
                            {/*        </div>*/}
                            {/*        <div className="blog-feeds pe-15">*/}
                            {/*            {*/}
                            {/*                news.map(item => <HomeSingleFooterBlog key={item.id} {...item}/>)*/}
                            {/*            }*/}
                            {/*        </div>*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                            <div className="col-xl-3 col-lg-6 col-md-6">
                                <div className="footer-widget h4footer-widget mb-40">
                                    <div className="footer-title">
                                        <h3>Hours</h3>
                                    </div>
                                    <div className="h4events-list mb-30">
                                        <ul>
                                            <li><i className="fal fa-clock"/><span>Monday - Friday <span>08:30 am - 04:30pm</span></span>
                                            </li>
                                            <li><i className="fal fa-times-square"/><span className="close-days">Saturday - Sunday [Closed]</span>
                                            </li>
                                        </ul>
                                    </div>
                                    {/*<div className="h4footer-social">*/}
                                    {/*    <ul className="list-inline">*/}
                                    {/*        <li><a href="#"><i className="fab fa-facebook-f"></i></a></li>*/}
                                    {/*        <li><a href="#"><i className="fab fa-twitter"></i></a></li>*/}
                                    {/*        <li><a href="#"><i className="fab fa-google"></i></a></li>*/}
                                    {/*    </ul>*/}
                                    {/*</div>*/}
                                </div>
                            </div>
                            <div className="col-xl-2 col-lg-6 col-md-6">
                                <div className="footer-widget h4footer-widget mb-40">
                                    <div className="footer-title">
                                        <h3>More Links</h3>
                                    </div>
                                    <div className="footer-menu footer-menu-2 h4footer-menu">
                                        <ul>
                                            <li><Link to="/">Home</Link></li>
                                            <li><Link to="/servicesDetails">About</Link></li>
                                            <li><Link to="/servicesDetails">Contact</Link></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer-bottom pt-25 pb-20">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-12">
                                <div className="footer-copyright footer-copyright-3 text-center">
                                    <p>Copyright by@ LifeCare - 2022</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    );
};

export default HomeFourFooter;