import React from 'react';
import SingleAppointmentInputField from '../../../components/SingleAppoinmentInputField/SingleAppointmentInputField';
import Swal from "sweetalert2";
import {useForm} from "react-hook-form";
import axios from "../../../api/axios";

const HomeFourAppoinment = () => {
    const {register, handleSubmit, reset} = useForm();

    const onSubmit = async (data) => {
        try {
            data['is_appointment'] = true
            const response = await axios.post('api/v1/messaging/contact/', data);
            reset()
            return Swal.fire({
                icon: 'success',
                // title: 'Oops...',
                text: 'Your request submitted successfully. We will contact you shortly.',
                timer: 5000,
                timerProgressBar: true
            })

        } catch (err) {
            const data = err?.response?.status < 500 ? err.response.data : {'server-error': ['Something went wrong please try again later.']}
            return Swal.fire({
                icon: 'error',
                // title: 'Oops...',
                text: Object.values(data).join(),
                timer: 2000,
                timerProgressBar: true
            })
        }
    }

    return (
        <>
            <section className="appoinment pos-rel">
                <div className="h4appoinment-thumb"/>
                <div className="container-fluid fix">
                    <div className="col-xl-6 offset-xl-6">
                        <div className="h4appoinment-wrapper">
                            <div className="about-title mb-50">
                                <h5 className="pink-color">Book Sleep Study</h5>
                                <h1>Book Your Sleep Study<br/> With LifeCare Today.</h1>
                            </div>
                            <form className="h4appoinment-form mb-15" onSubmit={handleSubmit(onSubmit)}>
                                <div className="row">

                                    <SingleAppointmentInputField
                                        register={{...register('name')}}
                                        placeholderText="Enter Full Name"
                                        icon="fal fa-user"
                                        type={'text'}
                                        maxLength={100}
                                    />
                                    <SingleAppointmentInputField
                                        register={{...register('email')}}
                                        placeholderText="Enter Your Email"
                                        icon="far fa-envelope-open"
                                        type={'email'}
                                        maxLength={100}
                                    />
                                    <SingleAppointmentInputField
                                        register={{...register('phone_no')}}
                                        placeholderText="Enter Your Phone"
                                        icon="fal fa-phone"
                                        type={'text'}
                                        maxLength={20}
                                    />
                                    <SingleAppointmentInputField
                                        register={{...register('service')}}
                                        placeholderText="Choose A Services"
                                        icon="fal fa-file-medical-alt"
                                        type={'text'}
                                        maxLength={20}
                                    />

                                </div>
                                <div className="h4appoinment-button">
                                    <button type={'submit'} className="primary_btn btn-icon ml-0"
                                            style={{animationDelay: '0.6s'}} tabIndex="0"><span>+</span>Make Appointment
                                    </button>
                                </div>
                            </form>

                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default HomeFourAppoinment;