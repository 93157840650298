import React from 'react';
import {Link} from 'react-router-dom';
import {lifeCareServices} from "../../pages/Services/utils";

const HomeThreeHiring = ({btn_text, h4hireText, title = 'About Us', pinkColor}) => {
    return (
        <>
            <div className={`hire-text hire-text-2 ${h4hireText && h4hireText}`}>
                <div className="about-title mb-20">
                    <h5 className={`${pinkColor && pinkColor}`}>{title}</h5>
                    <h1>Why LifeCare is the right choice?</h1>
                </div>
                <div className="about-text">
                    <p>
                        At LifeCare we offer the best customer care and we offer top of the line products to all of our
                        clients. In 33+ years in our community we where able to help 1000’s of people just like you.
                        Here is some more information about sleep apnea.
                    </p>
                </div>
                <ul className="professinals-list pt-10 pb-20">
                    {
                        lifeCareServices.map((item, index) => {
                            return (
                                <li key={index}>
                                    <i className="fa fa-check"/>
                                    {
                                        item?.id ? <Link to={`/details/${item.id}`}>{item.title}</Link>: item.title
                                    }
                                </li>
                            )
                        })
                    }
                </ul>
                <div className="hiring-button">
                    <Link to="/contact"
                          className="primary_btn btn-icon btn-icon-green ml-0"><span>+</span>{btn_text}</Link>
                </div>
            </div>
        </>
    );
};

export default HomeThreeHiring;
