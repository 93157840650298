import React from 'react';
import CommonPageHeader from '../../components/CommonPageHeader/CommonPageHeader';
import HomeFact from '../Home/Home/HomeFact/HomeFact';
import AboutArea from './AboutArea/AboutArea';
import HomeFourHiring from "../HomeFour/HomeFourHiring/HomeFourHiring";
import HomeFourFact from "../HomeFour/HomeFourFact/HomeFourFact";

const AboutUs = () => {
   return (
      <>
         <CommonPageHeader title="About Us" subtitle="About" />
          <AboutArea/>
          {/*<HomeFourFact/>*/}
          <HomeFourHiring/>
          <HomeFact/>
      </>
   );
};

export default AboutUs;