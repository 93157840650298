import React from 'react';
import {Offcanvas} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import useAuth from "../../../hooks/useAuth";

const Sidebar = ({show, handleClose}) => {
    const {user, getRefreshToken, logout} = useAuth();
    return (
        <>

            <div>
                <Offcanvas show={show} onHide={handleClose} placement='end' className='side__bar'>
                    <Offcanvas.Header closeButton>
                        {/* <Offcanvas.Title>Offcanvas</Offcanvas.Title> */}
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                        <ul>
                            <li className="p-2"><Link to="/">Home</Link></li>
                            <li className="p-2"><Link to="/about">About</Link></li>
                            <li className="p-2"><Link to="/contact">Contact</Link></li>
                            <li><Link to="/sleep-apnea">What is OSA?</Link></li>
                            {/*{!user.is_staff && <li className="p-2"><Link to="/chat/representatives">Representatives</Link></li>}*/}
                            {/*<li className="p-2"><Link to="/chat">Chat</Link></li>*/}
                        </ul>
                        {
                            // getRefreshToken() ?
                            //     <span className={"btn-outline-light p-2 rounded-2 btn"} onClick={logout}>
                            //                         <i className={"fa fa-user"}
                            //                            style={{marginRight: "10px"}}/>{user?.first_name + ' ' + user.last_name}
                            //                     </span>
                            //     :
                            //     <Link className={"btn-outline-success p-2 rounded-2 btn"} to="/login">
                            //         Sign In <i className="fa fa-arrow-right"/>
                            //     </Link>
                        }
                    </Offcanvas.Body>
                </Offcanvas>
            </div>
        </>
    );
};

export default Sidebar;
