import React from 'react';
import {Link} from 'react-router-dom';
import {useForm} from "react-hook-form";
import axios from "../../../api/axios";
import Swal from "sweetalert2";

const ContactFormArea = () => {
    const {register, handleSubmit, reset} = useForm();

    const onSubmit = async (data) => {
        try {
            const response = await axios.post('api/v1/messaging/contact/', data);
            reset()
            return Swal.fire({
                icon: 'success',
                // title: 'Oops...',
                text: 'Your request submitted successfully. We will contact you shortly.',
                timer: 5000,
                timerProgressBar: true
            })

        } catch (err) {
            const data = err?.response?.status < 500 ? err.response.data : {'server-error': ['Something went wrong please try again later.']}
            return Swal.fire({
                icon: 'error',
                // title: 'Oops...',
                text: Object.values(data).join(),
                timer: 2000,
                timerProgressBar: true
            })
        }
    }

    return (
        <>
            <section className="contact-form-area gray-bg pt-100 pb-100">
                <div className="container">
                    <div className="form-wrapper">
                        <div className="row align-items-center">
                            <div className="col-xl-8 col-lg-8">
                                <div className="section-title mb-55">
                                    <p><span/> Anything On your Mind</p>
                                    <h1>Send Us A Message Today</h1>
                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-3 d-none d-xl-block ">
                                <div className="section-link mb-80 text-end">
                                </div>
                            </div>
                        </div>
                        <div className="contact-form">
                            <form id="contact-form" onSubmit={handleSubmit(onSubmit)}>
                                <div className="row">
                                    <div className="col-lg-6">
                                        <div className="form-box user-icon mb-30">
                                            <input
                                                type="text"
                                                required
                                                maxLength={100}
                                                placeholder="Your Name"
                                                {...register('name')}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="form-box email-icon mb-30">
                                            <input
                                                type="email"
                                                required
                                                maxLength={100}
                                                placeholder="Your Email"
                                                {...register('email')}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="form-box phone-icon mb-30">
                                            <input
                                                type="text"
                                                required
                                                maxLength={20}
                                                placeholder="Your Phone"
                                                {...register('phone_no')}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="form-box subject-icon mb-30">
                                            <input
                                                type="text"
                                                required
                                                maxLength={250}
                                                placeholder="Your Subject"
                                                {...register('subject')}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-lg-12">
                                        <div className="form-box message-icon mb-30">
                                            <textarea
                                                required
                                                cols="30"
                                                rows="10"
                                                id="message"
                                                maxLength={1000}
                                                placeholder="Your Message"
                                                {...register('message')}
                                            />
                                        </div>
                                        <div className="contact-btn text-center">
                                            <button className="primary_btn btn-icon ml-0" type="submit">
                                                <span>+</span> Book Now
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                            <p className="ajax-response text-center"/>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default ContactFormArea;
