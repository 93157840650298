import {createContext, useEffect, useState} from "react";
import {axiosPrivate} from "../api/axios";

const MessageContext = new createContext({
    users: null,
    setUsers: users => null,
    messageTo: {},
    setMessageTo: messageTo => null,
    updateChat: async (item, remove = false) => null
});

export const MessageProvider = ({children}) => {
    const [messageTo, setMessageTo] = useState(null);
    const [users, setUsers] = useState(null);
    let isMounted = true;
    const controller = new AbortController();
    const getUsers = async () => {
        try {
            const response = await axiosPrivate.get("api/v1/messaging/chats/", {signal: controller.signal});
            isMounted && setUsers(response.data.results);
        } catch (err) {
            console.log(err);
        }
    };

    useEffect(() => {
        getUsers().then();
        return () => {
            isMounted = false;
            controller.abort();
            setMessageTo(null)
        };
    }, []);

    const updateChat = async (item, remove = false) => {
        const path = `api/v1/messaging/user-contacts/${item.id}/`;
        try {
            remove ? await axiosPrivate.delete(path) : await axiosPrivate.patch(path, {allowed: true});
            await getUsers();
        } catch (err) {
            console.log(err);
        }
    }

    const stateValues = {
        users,
        setUsers,
        messageTo,
        setMessageTo,
        updateChat
    }

    return (
        <MessageContext.Provider value={stateValues}>
            {children}
        </MessageContext.Provider>
    )
}

export default MessageContext;