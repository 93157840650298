import React from 'react';
import HomeFourAbout from './HomeFourAbout/HomeFourAbout';
import HomeFourAppoinment from './HomeFourAppoinment/HomeFourAppoinment';
import HomeFourBlogs from './HomeFourBlogs/HomeFourBlogs';
import HomeFourFact from './HomeFourFact/HomeFourFact';
import HomeFourHeroArea from './HomeFourHeroArea/HomeFourHeroArea';
import HomeFourHiring from './HomeFourHiring/HomeFourHiring';
import HomeFourHowWorks from './HomeFourHowWorks/HomeFourHowWorks';
import HomeFourServices from './HomeFourServices/HomeFourServices';
import QurioLead from "../../components/QurioLead/QurioLead";

const HomeFour = () => {
    return (
        <>
            <HomeFourHeroArea/>
            <HomeFourAbout/>
            <HomeFourServices/>
            <HomeFourAppoinment/>
            <HomeFourFact/>
            <HomeFourHowWorks/>
            <HomeFourBlogs/>
        </>
    );
};

export default HomeFour;
